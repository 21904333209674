<section class="banner">
	<div class="container">
		<div class="row align-items-center justify-content-center">
			<div class="col-md-6">
				<div class="banner-content stores">
					<span>Fast and safe</span>
					<h1>International money transfers</h1>
					<p>Thousands of customers have trusted Tayo Pay to send money from South Africa. International money
						transfer has never been easier.</p>
					<img src="assets/images/googleplay_white.png"><span class="mx-2"></span> <img
						src="assets/images/appstore_white.png">
				</div>
			</div>
			<div class="col-md-6">
				<div class="">
					<img src="assets/images/send-money.jpg" alt="" class="img-fluid w-100">
				</div>
			</div>
		</div> <!-- / .row -->
	</div> <!-- / .container -->
</section>
<section class="feature ">
	<div class="container">
		<div class="row">
			<div class="col-lg-3 col-sm-6 col-md-6">
				<div class="feature-item">
					<i class="fa-solid fa-jet-fighter-up c1"></i>
					<h4>FAST</h4>
				</div>
			</div>
			<div class="col-lg-3 col-sm-6 col-md-6">
				<div class="feature-item">
					<i class="fa-solid fa-lock c2"></i>
					<h4>SECURE</h4>
				</div>
			</div>
			<div class="col-lg-3 col-sm-6 col-md-6">
				<div class="feature-item">
					<i class="fa-solid fa-handshake c3"></i>
					<h4>COMPETITIVE</h4>
				</div>
			</div>
			<div class="col-lg-3 col-sm-6 col-md-6">
				<div class="feature-item">
					<i class="fa-solid fa-thumbs-up c4"></i>
					<h4>GUARANTEED</h4>
				</div>
			</div>
		</div>
	</div>
</section>
<section class="section about" id="aboutus">
	<div class="container">
		<div class="row">
			<div class="col-lg-5">
				<div class="about-img mb-5 mb-lg-0">
					<img src="assets/images/about-us.jpg" alt="" class="img-fluid">
				</div>
			</div>

			<div class="col-lg-7">
				<div class="about-item">
					<h2>About TayoPay</h2>
					<div [innerHTML]="content.pageContent"></div>
					<a routerLink="aboutus" class="btn btn-info text-white">Read more <i
							class="fa fa-angle-right ml-2"></i></a>
				</div>
			</div>
		</div>
	</div>
</section>

<section>
	<div class="container-fluid service-bg text-white py-5">
		<div class="container">
			<div class="row text-center">
				<div class="col-lg-12">
					<div class="mb-4">
						<h2>Our Services</h2>
					</div>
				</div>
			</div>
			<div class="row text-center py-5">
				<div class="col-md-3">
					<div>
						<img src="assets/images/cash-payment.webp" class="card-img-top w-50">
						<div>
							<h5>Cash Payments</h5>
							<p>Choose from 1000's of stores, banks and kiosks where you can safely deposit your cash to be sent across borders to any pay-out partner that you select in your chosen country to reach family safely, securely and efficiently.</p>
						</div>
					</div>
				</div>
				<div class="col-md-3">
					<div>
						<img src="assets/images/credit-card-payment.webp" class="card-img-top w-50">
						<div>
							<h5>Credit Card Payments</h5>
							<p>You can make credit card payments at any retail store or bank to transfer funds to your chosen pay-out partner, where your family can safely withdraw their funds.</p>
						</div>
					</div>
				</div>
				<div class="col-md-3">
					<div>
						<img src="assets/images/mobile-wallet.webp" class="card-img-top w-50">
						<div>
							<h5>Mobile Wallet</h5>
							<p>You can select mobile wallet on our App to transmit funds anywhere in the world, and the money will be instantly available for your family overseas to withdraw.</p>
						</div>
					</div>
				</div>
				<div class="col-md-3">
					<div>
						<img src="assets/images/eft.webp" class="card-img-top w-50">
						<div>
							<h5>EFT</h5>
							<p>You can access our instant bank transfer or EFT option using the easy functions on our App. You have instant remittance at your fingertips, and you don't even have to leave home.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="section service" id="howitworks">
	<div class="container">
		<div class="row">
			<div class="col-lg-7">
				<div class="service-heading mb-4">
					<h2>How It Works</h2>
				</div>

				<div class="row">
					<div class="col-lg-6 col-md-6">
						<div class="service-item">
							<ul class="list-unstyled">
								<li class="mb-4">
									<div class="about-item-block">
										<div class="about-item-icon">
											1
										</div>
										<div class="about-item-content">
											<h4>DOWNLOAD MOBILE APP</h4>
										</div>
									</div>
									<small>Our App is free to download. Click on the relevant button above or go to Google Play & Apple App Stores to download.</small><br>
									<i class="fa-brands fa-android" aria-hidden="true"></i> <i
										class=" mx-2 fa-brands fa-apple" aria-hidden="true"></i>
								</li>
								<li>
									<div class="about-item-block">
										<div class="about-item-icon">
											3
										</div>
										<div class="about-item-content">
											<h4>CREATE AN ORDER</h4>
										</div>
									</div>
									<small>Add the recipient details or select from your beneficiaries that you’ve pre-loaded. Select which option you require the money to be sent to i.e. mobile wallet or bank account details, then visit any of our countrywide retail vendors.</small>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-lg-6 col-md-6">
						<div class="service-item">
							<ul class="list-unstyled">
								<li class="mb-4">
									<div class="about-item-block">
										<div class="about-item-icon">
											2
										</div>
										<div class="about-item-content">
											<h4>SIGN UP FOR FREE</h4>
										</div>
									</div>
									<small>Once you’ve downloaded the App, you can register on the mobile app in a few hassle-free steps. You will need to provide your identification document as well as an email address.</small>
								</li>
								<li>
									<div class="about-item-block">
										<div class="about-item-icon">
											4
										</div>
										<div class="about-item-content">
											<h4>MAKE PAYMENT</h4>
										</div>
									</div>
									<small>You can pay cash at any of our national retail partners listed on our website or you can pay via EFT and online banking.</small>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-5 d-md-none d-lg-block">
				<div class="service-img mt-5 mt-lg-0">
					<img src="assets/images/send-receive-money.jpg" alt="" class="img-fluid w-100">
				</div>
			</div>
		</div>
	</div>
	<div class="container my-5 pt-5 partners">
		<div class="row text-center">
			<div class="col-md-12">
				<h2>Pay In Partners</h2>
			</div>
		</div>
		<div class="row mt-5 text-center">
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/absa.jpg">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/capitec.jpg">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/fnb-pay.jpg">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/nedbank.jpg">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/rmb.jpg">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/sb.jpg">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Ackermans.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Boxer.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Builders.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Cambridge.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Checkers.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Flash.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Game.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Kazang.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Makro.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Mastercard.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Masterpass.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/PEP.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Pick-n-Pay.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Rhino.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Shoprite.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Snapscan.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Stitch.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Top-it-Up.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Usave.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Visa.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Vodacom.png">
			</div>
			<div class="col-md-2">
				<img class="img-fluid" src="assets/images/partners/Zapper.png">
			</div>
		</div>
	</div>
</section>