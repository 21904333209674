<section class="footer" id="contactus">
	<div class="container">
		<div class="row">
			<div class="col-lg-3 mr-auto col-sm-6 col-md-6">
				<div class="widget footer-widget mb-5 mb-lg-0">
					<img src="assets/images/tayopay-logo-white.svg" alt="TayoPay" class="img-fluid">
					<p class="mt-3">TayoPay was founded in 2018 and is licensed and regulated by the South African Reserve Bank. Our mission is to simplify access to financial services for Foreign Nationals in South Africa.</p>
				</div>
			</div>
			<div class="col-lg-3 col-sm-6 col-md-6">
				<div class="widget footer-widget mb-5 mb-lg-0">
					<h5 class="widget-title">Legal Pages</h5>
					<ul class="list-unstyled footer-links">
						<li><a routerLink="/terms">Terms & Condition</a></li>
						<li><a routerLink="/privacypolicy">Privacy Policy</a></li>
						<li><a routerLink="/cookiepolicy">Cookie Policy</a></li>
						<li><a routerLink="/disclaimer">Disclaimer</a></li>
						<li><a href="assets/files/Tayo-Pay-PAIA-Manual.pdf" target="_blank">PAIA Manual</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-3 col-sm-6 col-md-6">
				<div class="widget footer-widget mb-5 mb-lg-0">
					<h5 class="widget-title">Follow Us</h5>
					<ul class="list-inline footer-socials">
						<li class="list-item">
							<a href="https://www.facebook.com/TayoPaySA/" target="_blank"> <i class="fab fa-facebook-f"></i>Facebook</a>
						</li>
						<li class="list-item py-2">
							<a href="https://www.instagram.com/tayo.pay/" target="_blank"> <i class="fab fa-instagram"></i>Instagram</a>
						</li>
						<!-- <li class="list-item">
							<a href="#"> <i class="fab fa-twitter"></i>Twitter</a>
						</li>
						<li class="list-item py-2">
							<a href="#"> <i class="fab fa-linkedin"></i>Linkedin</a>
						</li> -->
					</ul>
				</div>
			</div>
			<div class="col-lg-3 col-sm-6 col-md-6">
				<div class="widget footer-widget mb-5 mb-lg-0">
					<h5 class="widget-title">Location</h5>
					<p>The Bridge, 304 Durban Road, Bellville,7530. </p>
					<ul class="list-unstyled">
						<li>E : info@tayopay.co.za</li>
						<li>P : +27-21-023-3333</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>

<div class="footer-btm">
	<div class="container">
		<div class="row">
			<div class="col-lg-6">
				<div class="copyright">
					<p>@ 2018 - 2024 Copyright - Designed & Developed by <a href="http://tayseertech.com/" target="_blank">Tayseer Technologies</a></p>
				</div>
			</div>
		</div>
	</div>
</div>