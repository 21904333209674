import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ng-tayopay';
  route: any;
  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      this.route = location.pathname;
      if (this.route.indexOf('/') >= 0 ) {
        if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
      }
    });
}
}
