<div class="container-fluid bg-light">
    <div class="row">
        <div class="col-md-12 mx-auto hOnePageTitle">
            <h1 class="p-4 text-center">About Us</h1>
        </div>
    </div>
</div>
<div class="container single-page mb-5">
    <div class="row">
        <div class="col-md-12 py-5">
            <div [innerHTML]="content.pageContent"></div>
        </div>
    </div>
    <div class="row text-center">
        <div class="col-md-4">
            <div class="card">
                <img src="assets/images/vision.webp" class="card-img-top">
                <div class="card-body">
                    <h5 class="card-title">Vision</h5>
                    <p class="card-text">Our vision is to become the most trusted and innovative money transfer service
                        provider in the industry. We strive to continuously improve our services and technology to meet
                        the evolving needs of our customers.</p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <img src="assets/images/values.webp" class="card-img-top">
                <div class="card-body">
                    <h5 class="card-title">Value</h5>
                    <p class="card-text">At Tayo Pay, we believe in honesty, integrity, and transparency. We are committed to treating our customers and partners with respect and fairness, and to uphold the highest ethical standards in everything we do.</p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <img src="assets/images/platform.webp" class="card-img-top">
                <div class="card-body">
                    <h5 class="card-title">Platform</h5>
                    <p class="card-text">We use state-of-the-art technology to ensure that our customers' transactions are processed quickly and securely. Our platform is designed to be user-friendly and easy to navigate, making it simple for you to send money across borders.</p>
                </div>
            </div>
        </div>
    </div>
</div>