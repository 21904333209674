<div class="container-fluid bg-light">
    <div class="row">
        <div class="col-md-12 mx-auto hOnePageTitle">
            <h1 class="p-4 text-center">About Us</h1>
        </div>
    </div>
</div>
<div class="container single-page mb-5">
    <div class="row">
        <div class="col-md-12">
            <div [innerHTML]="content.pageContent"></div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <h5>With Tayo Pay, you can expect:</h5>
        </div>
        <!-- <div class="col-md-4">
            <div class="card">
                <img src="assets/images/vision.webp" class="card-img-top">
                <div class="card-body">
                    <h5 class="card-title">Vision</h5>
                    <p class="card-text">Our vision is to become the most trusted and innovative money transfer service
                        provider in the industry. We strive to continuously improve our services and technology to meet
                        the evolving needs of our customers.</p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <img src="assets/images/values.webp" class="card-img-top">
                <div class="card-body">
                    <h5 class="card-title">Value</h5>
                    <p class="card-text">At Tayo Pay, we believe in honesty, integrity, and transparency. We are committed to treating our customers and partners with respect and fairness, and to uphold the highest ethical standards in everything we do.</p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <img src="assets/images/platform.webp" class="card-img-top">
                <div class="card-body">
                    <h5 class="card-title">Platform</h5>
                    <p class="card-text">We use state-of-the-art technology to ensure that our customers' transactions are processed quickly and securely. Our platform is designed to be user-friendly and easy to navigate, making it simple for you to send money across borders.</p>
                </div>
            </div>
        </div> -->
    <div class="col-md-12">
        <ul>
            <li><strong><u>Speed and Reliability:</u></strong> Fast transfers that meet your needs without delays.</li>
            <li><strong><u>Security You Can Trust:</u></strong> Industry-leading encryption ensures your money and personal information are always protected.</li>
            <li><strong><u>Global Reach:</u></strong> Send money to countless destinations around the globe with ease.</li>
            <li><strong><u>Affordable Solutions:</u></strong> Transparent pricing and competitive rates, because we believe your money should go further.</li>
        </ul>
        <p>We pride ourselves on being more than just a money transfer service—we are a bridge that connects families, empowers dreams, and supports communities worldwide. At Tayo Pay, your trust is our greatest asset, and we are dedicated to providing you with a remittance experience that is seamless, secure, and tailored to your needs.</p>
        <p>Join the growing Tayo Pay family today and experience the difference of a service built around trust, quality, and care.</p>
    </div>
    </div>
</div>