<div class="container-fluid bg-light">
  <div class="row">
    <div class="col-md-12 mx-auto hOnePageTitle">
      <h1 class="p-4 text-center">FAQ's</h1>
    </div>
  </div>
</div>
<div class="container single-page my-5">
  <div class="row">
    <div class="col-md-12 pb-5">
      <h5>Before contacting customer support, please see if you can find the answer to your query here:</h5><br>
      <div id="accordion">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Where can I find the Tayo Pay App?
              </button>
            </h6>
          </div>

          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
            <div class="card-body">
              <p>Download the Tayo Pay App on your phone from Google Play Store or Apple App Store.
                Then follow the easy steps to register.</p>
              <img src="assets/images/googleplay_white.png"><span class="mx-2"></span> <img
                src="assets/images/appstore_white.png">
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left collapsed" data-toggle="collapse"
                data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                What documents do I need to register?
              </button>
            </h6>
          </div>
          <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
            <div class="card-body">
              <ul>
                <li>Passport or</li>
                <li>Foreign ID or</li>
                <li>South African ID or</li>
                <li>Asylum or Refugee Documents.</li>
              </ul>
              <p>
                Please make sure that your Asylum or refugee documents or passport has not expired.
              </p>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left collapsed" data-toggle="collapse"
                data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                How will I get my documents loaded on the App?
              </button>
            </h6>
          </div>
          <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#accordion">
            <div class="card-body">
              <p>We have made it very easy. The App will prompt you to either upload the document that you have stored
                on your phone, or to take a photo of your document as well as a selfie. Then click upload, and your
                documents will be submitted for verification.</p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="headingFour">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left collapsed" data-toggle="collapse"
                data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                Why do you need to identify me?
              </button>
            </h6>
          </div>

          <div>
            <div id="collapseFour" class="collapse show" aria-labelledby="headingFour" data-parent="#accordion">
              <div class="card-body">
                <p>We are required by law and the South African Reserve Bank to ask for proof of your identification as
                  part of our due diligence responsibilities as a remittance company. <br>
                  Identity verification is the process of checking that your ID/passport/Asylum or refugee documents are
                  valid and real, and that in fact, you are a real person, in order for us to facilitate your
                  transaction across borders.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="headingFive">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left collapsed" data-toggle="collapse"
                data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                Is my personal information safe with you?
              </button>
            </h6>
          </div>
          <div id="collapseFive" class="collapse show" aria-labelledby="headingFive" data-parent="#accordion">
            <div class="card-body">
              <p>Yes, it is. Your documentation and identity will be protected by law according to the POPIA act. All
                documentation will be stored on a secure server, and your information will not be shared with any
                external parties.</p>
            </div>
          </div>
        </div>


        <!-- New FAQ's START -->
        <div class="card">
          <div class="card-header" id="headingTwentytwo">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                data-target="#collapseTwentytwo" aria-expanded="false" aria-controls="collapseTwentytwo">
                How much money will I be able to send?
              </button>
            </h6>
          </div>
          <div id="collapseTwentytwo" class="collapse show" aria-labelledby="headingTwentytwo" data-parent="#accordion">
            <div class="card-body">
              <p>R5000.00 per day <br>
                R25 000.00 per month
              </p>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwentyThree">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                data-target="#collapseTwentyThree" aria-expanded="false" aria-controls="collapseTwentyThree">
                How often can I send money?
              </button>
            </h6>
          </div>
          <div id="collapseTwentyThree" class="collapse show" aria-labelledby="headingTwentyThree"
            data-parent="#accordion">
            <div class="card-body">
              <p>You can send every day as long as you don’t send more than R5000.00 daily. Once you have reached the
                R25 000.00 monthly limit, you won’t be able to send any more funds until the following month.</p>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwentyFour">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                data-target="#collapseTwentyFour" aria-expanded="false" aria-controls="collapseTwentyFour">
                How much will each transaction cost me?
              </button>
            </h6>
          </div>
          <div id="collapseTwentyFour" class="collapse show" aria-labelledby="headingTwentyFour"
            data-parent="#accordion">
            <div class="card-body">
              <p>FOREX rates and fees may vary according to the country and amount that you’re sending. A quote will be
                given to you to approve before you complete your transaction.</p>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwentyFive">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                data-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                How do I Create a Transaction?
              </button>
            </h6>
          </div>
          <div id="collapseTwentyFive" class="collapse show" aria-labelledby="headingTwentyFive"
            data-parent="#accordion">
            <div class="card-body">
              <ul>
                <li>Create a free account.</li>
                <li>Your identification will then be verified.</li>
                <li>Once you’ve been verified, you will be able to transact.</li>
                <li>Select a payout country.</li>
                <li>Add recipient information.</li>
                <li>Add payment information.</li>
                <li>You will be given a breakdown of the costs which you must approve.</li>
                <li>Then follow the easy prompts to complete your transaction.</li>

              </ul>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwentyFive">
            <h6 class="mb-0">
              <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                data-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                What is a Reference Number?
              </button>
            </h6>
          </div>
          <div id="collapseTwentyFive" class="collapse show" aria-labelledby="headingTwentyFive"
            data-parent="#accordion">
            <div class="card-body">
              <p>This is a unique number that will be issued automatically for every new transaction. This number will
                enable us to track your money and ensure that it reaches its destination. You will have to send that
                number to your recipient for them to be able to withdraw their money.</p>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card">
            <div class="card-header" id="headingTwentyFive">
              <h6 class="mb-0">
                <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                  data-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                  What Pay-in methods can I use?
                </button>
              </h6>
            </div>
            <div id="collapseTwentyFive" class="collapse show" aria-labelledby="headingTwentyFive"
              data-parent="#accordion">
              <div class="card-body">
                <p><strong>CASH:</strong> Through any of our pay-in partners.
                <strong>MOBILE WALLET:</strong> Select the mobile wallet as your payment method on the App and follow
                the easy instructions.
                <strong>EFT:</strong> Follow the instructions on the App after you’ve selected the EFT option.</p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwentyFive">
              <h6 class="mb-0">
                <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                  data-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                  Why will I get a quote first?
                </button>
              </h6>
            </div>
            <div id="collapseTwentyFive" class="collapse show" aria-labelledby="headingTwentyFive"
              data-parent="#accordion">
              <div class="card-body">
                <p>International FOREX rates fluctuate on an hourly basis, this is why we will first give you a quote to
                  approve or decline based on the current rates.</p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwentyFive">
              <h6 class="mb-0">
                <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                  data-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                  Can I see my Payment History on the App?
                </button>
              </h6>
            </div>
            <div id="collapseTwentyFive" class="collapse show" aria-labelledby="headingTwentyFive"
              data-parent="#accordion">
              <div class="card-body">
                <p>Yes. You will be able to see a list of your processed payments in your transactions list on the app.
                  When you click on a single transaction, it will give you more details relating to that transaction
                  .</p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwentyFive">
              <h6 class="mb-0">
                <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                  data-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                  Can I cancel, reverse or edit a transaction?
                </button>
              </h6>
            </div>
            <div id="collapseTwentyFive" class="collapse show" aria-labelledby="headingTwentyFive"
              data-parent="#accordion">
              <div class="card-body">
                <p>Kindly contact our support team if you require assistance with cancellations, reversals or changing recipient details. <br>
                  Unfortunately, we cannot assist once a transaction has been paid out to the recipient.
                </p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwentyFive">
              <h6 class="mb-0">
                <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                  data-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                  Can I recall a paid transaction?
                </button>
              </h6>
            </div>
            <div id="collapseTwentyFive" class="collapse show" aria-labelledby="headingTwentyFive"
              data-parent="#accordion">
              <div class="card-body">
                <p>Unfortunately, you can’t recall a transaction that has already been actioned or paid out to the
                  recipient.
                  If you entered incorrect information when sending money, we may try to recall the transfer for you,
                  but it cannot be guaranteed. <br>
                  Airtime top up and cash pickup transfers which are already paid cannot be recalled.
                </p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwentyFive">
              <h6 class="mb-0">
                <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                  data-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                  How can I reset my password?
                </button>
              </h6>
            </div>
            <div id="collapseTwentyFive" class="collapse show" aria-labelledby="headingTwentyFive"
              data-parent="#accordion">
              <div class="card-body">
                <p>We have 2 functions for retrieving/resetting your password:</p>
                <ul>
                  <li>Forgot password: When you open the app, you will see a function to forward password. You will
                    receive an email confirming your password.</li>
                  <li>Reset password: If you feel that your login information has been compromised, you should reset
                    your password. Open the App, click on ‘my account’, and follow the prompts to reset your password.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
        <div class="card-header" id="headingTwentyFive">
          <h6 class="mb-0">
            <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
              data-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
              Why won’t the App download on my phone?
            </button>
          </h6>
        </div>
        <div id="collapseTwentyFive" class="collapse show" aria-labelledby="headingTwentyFive" data-parent="#accordion">
          <div class="card-body">
            <p>It could be that your operating system doesn’t support the application, or it is possible that you don’t
              have enough storage on your device. </p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingTwentySix">
          <h6 class="mb-0">
            <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
              data-target="#collapseTwentySix" aria-expanded="false" aria-controls="collapseTwentySix">
              Customer Support
            </button>
          </h6>
        </div>
        <div id="collapseTwentySix" class="collapse show" aria-labelledby="headingTwentySix" data-parent="#accordion">
          <div class="card-body">
            <p>If these FAQs do not answer your questions or queries, please contact our support team:</p>
            <p>Email: support@tayopay.co.za <br>
              Phone: +27 87 711 0050
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>