    <!-- NAVBAR
    ================================================= -->
    <div class="main-navigation main_menu " id="mainmenu-area">
        <div class="container">
            <nav class="navbar navbar-expand-lg">
                <a class="navbar-brand" href="index.html">
                    <img src="assets/images/tayopay-logo.svg" alt="TayoPay" class="logo img-fluid">
                </a>
                <!-- Toggler -->
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>

                <!-- Collapse -->
                <div class="ml-auto">
                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <!-- Links -->
                    <ul class="navbar-nav ml-auto">
                    	 <li class="nav-item ">
                            <a href="index.html" class="nav-link js-scroll-trigger">
                                Home
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a href="#howitworks" class="nav-link js-scroll-trigger">
                                How it works
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="aboutus" class="nav-link js-scroll-trigger">
                                About
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="faq" class="nav-link js-scroll-trigger">
                                FAQ's
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a href="#contactus" class="nav-link">
                                Contact
                            </a>
                        </li>
                    </ul>

                     <ul class="list-inline header-contact float-lg-right">
                        <li class="list-inline-item">
                           <a href="tel:+27 21 023 3333" class="btn btn-outline-secondary"><i class="me-2 fa-solid fa-headphones-simple"></i>+27-21-023-3333</a>
                        </li>  
                        <li class="list-inline-item">
                        	<a href="#" class="text-white btn btn-info">PARTNER LOGIN <i class="ms-2 fa-solid fa-arrow-up-right-from-square"></i></a>
                        </li>
                    </ul>
                </div>
            </div> <!-- / .navbar-collapse -->
            </nav>
        </div> <!-- / .container -->
    </div>
